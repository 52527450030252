import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import STAR_PURPLE from "@assets/icons/star_purple.svg";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SVG from "react-inlinesvg";
import * as styles from "./ExperimentManager.module.scss";
import RECTANGLE_3 from "@assets/icons/Lims/rectangle-3.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-1.svg";

export const ExperimentManager = () => {
	const props: WhyLimsProps = {
		title: "Electronic Lab Notebook (ELN)",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"An Electronic Lab Notebook allows scientists to manage experiments and protocols within a single platform and integrate them with sample materials.",
		list: [
			{
				text: "Save up to 17 hours per week by using Genemod’s electronic lab notebook software",
				icon: STAR_PURPLE,
				id: 1,
			},
			{
				text: "Easily manage all the projects and experiments in one place",
				icon: STAR_PURPLE,
				id: 2,
			},

			{
				text: "Simplify workflow with an intuitive project management system",
				icon: STAR_PURPLE,
				id: 3,
			},
			{
				text: "Integrate inventory directly into the electronic lab notebook and keep experiments accurate",
				icon: STAR_PURPLE,
				id: 4,
			},
		],
		bodyTextColor: "text-helper",
		link: "INSIGHTS",
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_3} className={styles.rectangle3} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/tcr_dressed.png"}
					alt="Cloud ELN"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
		gap: "gapLg",
		sectionWrapperOverwrite: styles.sectionWrapperOverwrite,
	};
	return <SectionWhyLIMS {...props} />;
};
