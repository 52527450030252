import { StaticImage } from "gatsby-plugin-image";
import React, { FC, ReactNode } from "react";
import * as styles from "./ElnSoftwareInfoSection.module.scss";
import TypographyV2, { Variant } from "@components/TypographyV2/TypographyV2";

export type ElnSection = {
	title?: ReactNode;
	description?: ReactNode;
	image?: ReactNode;
};
export const ElnSoftwareInfoSection = () => {
	return (
		<div className={styles.ElnSoftwareInfoSectionWrapper}>
			<div className={styles.ElnSoftwareInfoSectionContent}>
				{elnSections.map((each, index) => (
					<div key={index} className={styles.ElnSoftwareInfoSection}>
						{each?.title && each?.title}
						{each?.description && each?.description}
						{each?.image && each?.image}
					</div>
				))}
			</div>
		</div>
	);
};

const TypoGraphyDescription: FC<{
	children: ReactNode;
	className?: string;
}> = ({ children, className }) => (
	<TypographyV2
		variant="BODY_TEXT_EXTRA_LARGE"
		mobileVariant="BODY_TEXT_EXTRA_LARGE"
		weight="REGULAR"
		color={"text-helper"}
		className={className}
	>
		{children}
	</TypographyV2>
);

const TypoGraphyTitle: FC<{ children: ReactNode; variant?: Variant }> = ({
	children,
	variant = "HEADING_2",
}) => (
	<TypographyV2
		variant={variant}
		weight="REGULAR"
		tabletVariant={variant}
		mobileVariant={variant}
		color="text-primary"
		className={styles.overRideTypography}
	>
		{children}
	</TypographyV2>
);

const elnSections: ElnSection[] = [
	{
		title: (
			<TypoGraphyTitle>
				What is an electronic lab notebook?
			</TypoGraphyTitle>
		),
		description: (
			<TypoGraphyDescription className={styles.titleDescription}>
				In the digital era, managing vast amounts of research data can
				be overwhelming. Traditional paper-based lab notebooks can be
				prone to loss, damage, or disorganization. This is where
				Electronic Lab Notebooks (ELNs) shine.  An Electronic Lab
				Notebook (ELN) is a software solution that is designed to
				replace a paper lab notebook or basic spreadsheet. They are used
				by scientists in many fields, particularly in the life sciences.
				ELNs have numerous advantages, and the benefits will be
				countless if it is used along with LIMS.
			</TypoGraphyDescription>
		),
	},
	{
		image: (
			<StaticImage
				loading="eager"
				src={"../../../assets/images/eln/rapid_transduction.png"}
				alt="ELN Folder Overview"
			/>
		),
	},
	{
		title: (
			<TypoGraphyTitle>
				Benefits of using an electronic lab notebook
			</TypoGraphyTitle>
		),
		description: (
			<div className={styles.BenefitsContent}>
				<TypoGraphyDescription>
					Some labs prefer to use a traditional database such as
					papers and Excel. However, this type of spreadsheet has its
					limitations. A fully digitized lab notebook, on the other
					hand, has many more advantages, including:
				</TypoGraphyDescription>
				<ul className={styles.lists}>
					<li className={styles.listItem}>
						<TypoGraphyDescription>
							Data Organization and Accessibility: Researchers can
							store and organize their data in a structured and
							searchable manner. From experimental protocols to
							observations and results, all information is readily
							accessible, enabling efficient data retrieval and
							analysis with data management systems.
						</TypoGraphyDescription>
					</li>
					<li className={styles.listItem}>
						<TypoGraphyDescription>
							Collaboration and Sharing: ELNs facilitate real-time
							collaboration by providing a centralized platform
							where researchers can collaborate on experiments,
							share data, and provide feedback.
						</TypoGraphyDescription>
					</li>
					<li className={styles.listItem}>
						<TypoGraphyDescription>
							Data Security and Backups: Digital Lab Notebook
							employs encryption and user access controls to
							ensure data privacy. Additionally, automatic backups
							safeguard against data loss due to hardware failure
							or other unforeseen events.
						</TypoGraphyDescription>
					</li>
					<li className={styles.listItem}>
						<TypoGraphyDescription>
							Version Control and Audit Trail: Researchers can
							track modifications made to their entries, ensuring
							transparency and enabling easy identification of any
							changes or discrepancies.
						</TypoGraphyDescription>
					</li>
					<li className={styles.listItem}>
						<TypoGraphyDescription>
							Integration with Other Tools: ELN software
							integrates seamlessly with analysis tools, such as
							statistical software or image analysis platforms.
						</TypoGraphyDescription>
					</li>
				</ul>
			</div>
		),
	},
	{
		title: (
			<TypoGraphyTitle variant="HEADING_3">
				Introducing genemod’s electronic lab notebook (ELN) software
			</TypoGraphyTitle>
		),
		description: (
			<TypoGraphyDescription className={styles.titleDescription}>
				As a leading provider of laboratory informatics solutions,
				Genemod offers a state-of-the-art Electronic Lab Notebook (ELN)
				software. Genemod's ELN software is designed to optimize data
				management, data collection, collaboration, and research
				efficiency. With its user-friendly interface and lightweight ELN
				for Life Sciences R&D teams, researchers can effortlessly
				document experiments, record observations, and store results,
				all within a centralized digital environment. Genemod's ELN
				software ensures data security and regulatory compliance. The
				platform generates audit trails for transparency and
				traceability. This data management system approach safeguards
				intellectual property and eases compliance demonstration during
				audits. The software also integrates smoothly with various
				laboratory instruments and analysis tools, such as Microsoft
				Excel, allowing researchers to import data directly into the ELN
				and analyze it within the platform. This integration enhances
				data integrity and streamlines research workflows, saving
				valuable time and minimizing errors. Genemod's ELN software
				fosters an efficient and productive research environment;
				researchers can focus on their core work and feel confident that
				their data is securely managed and easily accessible.
			</TypoGraphyDescription>
		),
	},
	{
		image: (
			<StaticImage
				loading="eager"
				src={"../../../assets/images/eln/notebook_eln.png"}
				alt="ELN Folder Overview"
			/>
		),
	},
	{
		title: (
			<TypoGraphyTitle variant="HEADING_3">
				What makes genemod’s eln better than other electronic lab
				notebook software?
			</TypoGraphyTitle>
		),
		description: (
			<TypoGraphyDescription className={styles.titleDescription}>
				Genemod offers ELN software that has a centralized dashboard, to
				optimize data management. Collaborators can organize data, track
				progress, and set priorities for lab projects. Once work is
				uploaded to the protocol library, it can be shared with other
				researchers. Some of the main features include:
			</TypoGraphyDescription>
		),
	},
	{
		title: (
			<TypoGraphyTitle variant="HEADING_3">
				Enhance the specificity of these points to spotlight client
				features
			</TypoGraphyTitle>
		),
		description: (
			<ul className={styles.lists}>
				<li>
					<TypoGraphyDescription>
						Centralized dashboard with shared real-time access to
						updates
					</TypoGraphyDescription>
				</li>
				<li>
					<TypoGraphyDescription>
						Easy cross-over and connection with each notebook page
					</TypoGraphyDescription>
				</li>
				<li>
					<TypoGraphyDescription>
						Permanent archiving for future sharing of reproducible
						experiments
					</TypoGraphyDescription>
				</li>
				<li>
					<TypoGraphyDescription>
						A virtual workspace allowing organization and sharing of
						all experimental protocols
					</TypoGraphyDescription>
				</li>
				<li>
					<TypoGraphyDescription>
						Real-time collaboration to reduce human error
					</TypoGraphyDescription>
				</li>
				<li>
					<TypoGraphyDescription>
						Secure storage of data for future retrieval
					</TypoGraphyDescription>
				</li>
			</ul>
		),
	},
	{
		title: (
			<TypoGraphyTitle variant="HEADING_3">Genemod AI</TypoGraphyTitle>
		),
		description: (
			<TypoGraphyDescription className={styles.titleDescription}>
				Genemod employed a game-changing ChatGPT feature in its
				Electronic Lab Notebook. With Genemod AI, scientists can now
				improve research reports by simply choosing different tones,
				checking grammars, and getting a draft of the research report
				based on the research data. Check out the one-of-a kind AI
				feature on ELN here.
			</TypoGraphyDescription>
		),
	},
];
