import React, { ReactNode } from "react";
import * as styles from "./SectionFAQ.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Accordion } from "@components/Accordion";
export const SectionFAQ = () => {
	const getBodyElement = (content: string | ReactNode) => {
		return (
			<TypographyV2
				variant="BODY_TEXT_EXTRA_LARGE"
				mobileVariant="BODY_TEXT_EXTRA_LARGE"
				weight="REGULAR"
				color="text-secondary"
			>
				{content}
			</TypographyV2>
		);
	};
	const QnAs = [
		{
			title: "Why should labs use an electronic lab notebook software?",
			id: "2",
			children: [
				`Transitioning to a fully digitized electronic lab notebook software offers numerous benefits, including streamlined data organization and accessibility, enhanced collaboration and sharing capabilities, improved data security and reliable backups, accurate version control with a clear audit trail, and seamless integration with various laboratory instruments and software.`,
			],
		},
		{
			title: "Benefits of using electronic lab notebook softwares",
			id: "3",
			children: [
				`Implementing an electronic lab notebook can revolutionize your laboratory into a cutting-edge hub of excellence. Primary advantages include increased efficiency through centralized data organization and quick access to information, greater accuracy by automating data entry and validation processes, and boosted productivity through enhanced collaboration, real-time data sharing, and smooth integration with various laboratory instruments and software.`,
			],
		},
		{
			title: "How can electronic lab notebooks accelerate and simplify R&D and production processes?",
			id: "4",
			children: [
				"Transitioning to a fully digitized electronic lab notebook offers numerous benefits, including streamlined data organization and accessibility, enhanced collaboration and sharing capabilities, improved data security and reliable backups, accurate version control with a clear audit trail, and seamless integration with various laboratory instruments and software.",
				<ul className={styles.lists}>
					<li>
						<b>Unified Platform:</b> A comprehensive ELN platform
						combines the functionalities of ELN software, LIMS, and
						informatics tools into a single system. This integration
						eliminates the need to juggle multiple software
						subscriptions and reduces the hassle of data transfer
						between systems.
					</li>
					<li>
						<b>Streamlined Operations:</b> By providing a holistic
						solution, an advanced ELN streamlines lab operations,
						allowing teams to focus on research rather than
						administrative tasks. This increased efficiency can
						significantly speed up research outcomes, leading to
						faster developments in R&D and production.
					</li>
					<li>
						<b>Enhanced Productivity:</b> With the elimination of
						integration issues, labs can maintain a seamless
						workflow. The unified approach prevents data silos and
						ensures that all team members have real-time access to
						the same information, fostering a collaborative
						environment that enhances productivity.
					</li>
					<li>
						<b>Efficiency Gains:</b> The ability to track and manage
						data within one ecosystem reduces errors and improves
						accuracy, allowing labs to accelerate their processes
						and achieve results quicker. This efficiency is crucial
						for labs looking to maintain a competitive edge in
						fast-paced research and production environments.
					</li>
				</ul>,
				"By leveraging the integrated capabilities of modern ELN, labs can truly transform how they manage data and conduct research, ultimately leading to more efficient and effective R&D and production processes.",
			],
		},
		{
			title: "What features make an electronic lab notebook user-friendly and intuitive for scientists?",
			id: "5",
			children: [
				`When it comes to electronic lab notebooks, ease of use is paramount. Intuitive design is at the heart of what makes these tools indispensable for researchers. A user-friendly ELN offers a straightforward and logical interface that facilitates quick learning and seamless integration into existing workflows.`,
				<b>Key Features of User-Friendly ELN:</b>,
				<ul className={styles.lists}>
					<li>
						<b>Customizable Templates:</b> Scientists can tailor and
						modify templates to suit various experimental protocols,
						ensuring flexibility while maintaining consistency
						across projects.
					</li>
					<li>
						<b>Data Organization:</b> The ability to easily catalog,
						retrieve, and cross-reference data reduces time spent on
						administrative tasks, allowing researchers to focus more
						on their experiments.
					</li>
					<li>
						<b>Collaborative Tools:</b> Built-in sharing and
						collaboration tools enable real-time collaboration among
						research team members, streamlining the peer review
						process and fostering innovation.
					</li>
					<li>
						<b>Integration Capabilities:</b> Compatibility with
						other research tools and software guarantees seamless
						data transfer and reduces redundancy, enhancing the
						overall research experience.
					</li>
				</ul>,
				<b>Enhancing Research Efficiency</b>,
				"A truly intuitive ELN is designed with the researcher in mind, providing an environment where they can document their findings effortlessly, explore data efficiently, and adapt to evolving research needs without a steep learning curve. These features collectively make electronic lab notebooks an invaluable asset for modern research organizations.",
			],
		},
		{
			title: "How can an electronic lab notebook help avoid redundant work among team members?",
			id: "6",
			children: [
				`Adopting a fully digital electronic lab notebook provides a range of advantages, such as efficient data organization and easy access, improved collaboration and sharing options, strengthened data security with dependable backups, precise version control with a transparent audit trail, and smooth integration with diverse laboratory instruments and software.`,
				`An ELN can significantly help avoid redundant work by ensuring that all team members have real-time access to ongoing projects and experiments. Visibility into teammates' activities prevents duplication of effort and fosters a more efficient workflow. With everyone on the same page, teams can allocate their time and resources more effectively.`,
				`Moreover, the enhanced collaboration features of an ELN not only improve communication but also ensure that any changes or updates are immediately visible to the entire team. This heightened awareness of each other's progress ensures that researchers can focus on innovation rather than redoing tasks already completed by others.`,
				`Incorporating these elements into your lab operations not only boosts productivity but also supports a culture of transparency and efficiency, making it a vital tool for modern scientific research environments.`,
			],
		},
		{
			title: " How can electronic lab notebooks streamline training and regulatory compliance processes?",
			id: "7",
			children: [
				"Transitioning to a fully digitized electronic lab notebook offers numerous benefits, including streamlined data organization and accessibility, enhanced collaboration and sharing capabilities, improved data security and reliable backups, accurate version control with a clear audit trail, and seamless integration with various laboratory instruments and software.",
				"Moreover, ELN play a crucial role in streamlining training and regulatory compliance. With a centralized platform, training personnel becomes more efficient as everything from standard operating procedures to compliance checklists can be accessed effortlessly. This ensures that team members are consistently educated on internal processes and regulatory standards, reducing the risk of errors and non-compliance.",
				"By providing a structured framework for documentation, ELN enable labs to maintain thorough and accurate records, simplifying the process of meeting regulatory requirements. This not only enhances productivity but also boosts confidence in audits and inspections. The ability to quickly retrieve and verify information through clear audit trails further underscores the capability of ELN to support compliance and efficient training.",
				"In summary, the integration of an ELN system not only enhances day-to-day lab operations but also fortifies the training and regulatory compliance framework, making it an essential tool for modern laboratories striving for excellence.",
			],
		},
		{
			title: "What additional functionalities beyond a traditional electronic lab notebook can enhance lab operations?",
			id: "8",
			children: [
				<React.Fragment>
					A Genemod's <u>LIMS</u> is engineered to be the central hub
					for laboratory management, integrating various
					functionalities such as inventory tracking, electronic lab
					notebooks, equipment scheduling, order management, and many
					other scientific research features into a unified platform.
					This integration facilitates a seamless, efficient workflow,
					enabling scientists to concentrate on their core research
					objectives with fewer administrative burdens.
				</React.Fragment>,
				<b>Beyond Traditional ELN Capabilities</b>,
				`By embracing a holistic approach, Genemod's platform not only streamlines inventory and scheduling but also incorporates advanced laboratory information management system (LIMS) capabilities and informatics tools. This comprehensive integration eliminates the need for multiple software subscriptions and cumbersome data transfers between disparate systems.`,
				<b>Key Benefits:</b>,
				<ul className={styles.lists}>
					<li>
						<b>Simplified Lab Operations:</b> With everything in one
						place, lab operations become more cohesive, reducing the
						time spent on administrative tasks.
					</li>
					<li>
						<b>Enhanced Efficiency:</b> The unified platform boosts
						productivity by allowing researchers to focus on what
						truly matters—scientific discovery.
					</li>
					<li>
						<b>Accelerated Research Outcomes:</b> By minimizing
						logistic hurdles, labs can achieve faster progress in
						their research endeavors.
					</li>
				</ul>,
				`Incorporating these additional functionalities ensures a robust solution for modern laboratories, driving efficiency and innovation without the hassle of juggling various software tools.`,
			],
		},
	];

	return (
		<div className={styles.container}>
			<TypographyV2
				variant="HEADING_2"
				weight="MEDIUM"
				tabletVariant="HEADING_2"
				mobileVariant="HEADING_2"
				color="brand-500"
				className={styles.heading}
			>
				Frequently asked questions
			</TypographyV2>

			{QnAs?.map((qna) => {
				return (
					<div key={qna.id}>
						<Accordion
							title={qna.title}
							titleClassName={styles.customTextStyle}
							bodyClassName={styles.customTextStyle}
						>
							{qna.children?.map((body_content, index) => {
								return (
									<div key={index}>
										{getBodyElement(body_content)}
									</div>
								);
							})}
						</Accordion>
					</div>
				);
			})}
		</div>
	);
};
