// extracted by mini-css-extract-plugin
export var LogoSectionWrapper = "index-module--LogoSectionWrapper--Xzugr";
export var heroContainer = "index-module--heroContainer--TdeP4";
export var heroWrapperOverride = "index-module--heroWrapperOverride--bn5EF";
export var industriesContainerOverride = "index-module--industriesContainerOverride--y5mtS";
export var industriesWrapper = "index-module--industriesWrapper--rf9Oj";
export var inputWrapperOverwrite = "index-module--inputWrapperOverwrite--jfz5d";
export var joinInstitutionBodyClass = "index-module--joinInstitutionBodyClass--3J7sx";
export var justTitleAndContentOverwrite = "index-module--justTitleAndContentOverwrite--5Wlof";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--7yg06";
export var ourPartners = "index-module--ourPartners--qDZNz";
export var overrideFirstImage = "index-module--overrideFirstImage--GhZhj";
export var overrideImagesCard = "index-module--overrideImagesCard--fLU4F";
export var overrideSecondImage = "index-module--overrideSecondImage--+3plt";
export var pageWrapper = "index-module--pageWrapper--ms71w";
export var rightSectionOverride = "index-module--rightSectionOverride--4h18b";
export var tabsGap = "index-module--tabsGap--OC95P";
export var tabsOverride = "index-module--tabsOverride--rexU8";
export var testimonialOverwrite = "index-module--testimonialOverwrite--q0dXF";