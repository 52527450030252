import React from "react";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_3 from "@assets/icons/Lims/rectangle-3.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-1.svg";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./ModernEln.module.scss";
import { WhyLimsProps } from "@components/LimsSoftware/SectionWhyLims/data";
import { SectionWhyLIMS } from "@components/LimsSoftware/SectionWhyLims";

export const ModernELN = () => {
	const props: WhyLimsProps = {
		title: "A modern ELN software",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"Genemod’s ELN software offers the most innovative features on the market, including unique capabilities that are unmatched by other software.",
		list: [
			{
				text: "Genemod AI: AI-powered electronic lab notebook",
				icon: STAR,
				id: 1,
			},
			{
				text: "Live document editing",
				icon: STAR,
				id: 2,
			},

			{
				text: "Compliance with global standards (21 CFR Part 11)",
				icon: STAR,
				id: 3,
			},
		],
		link: "GENEMOD_AI",
		bodyTextColor: "text-helper",
		sectionOverviewOverride: styles.sectionOverviewOverride,
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_3} className={styles.rectangle3} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/eln/eln-ai-chat.png"}
					alt="ELN Folder Overview"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
		sectionWrapperOverwrite: styles.sectionWrapperOverwrite,
	};
	return <SectionWhyLIMS {...props} />;
};
