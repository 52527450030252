import GENEMOD_FREEZER from "@assets/products/genemod_ELN_v6.mp4";
import { CentralProtocolHub } from "@components/ElnSoftware/CentralProtocolHub";
import { ComprehensivePM } from "@components/ElnSoftware/ComprehensivePM";
import { ElnSoftwareInfoSection } from "@components/ElnSoftware/ElnSoftwareInfoSection";
import { ExperimentManager } from "@components/ElnSoftware/ExperimentManager";
import { LiveEditing } from "@components/ElnSoftware/LiveEditing";
import { ModernELN } from "@components/ElnSoftware/ModernELN";
import { SectionFAQ } from "@components/ElnSoftware/SectionFAQ";
import { HomePageTestimonials } from "@components/HomePageTestimonials";
import { PageLayout } from "@components/index";
import { Hero } from "@components/LimsSoftware/Hero";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";
import { Colors } from "@helpers/Colors";
import React from "react";
import * as styles from "./index.module.scss";

type HeroProps = {
	title: string;
	sub_title: string;
	video_src: string;
	text: string;
	containerOverwrite?: string;
	heroWrapperOverride?: string;
	autoplay: boolean;
	bodyColor?: Colors;
};

export default function ELNSoftwarePage() {
	const heroProps: HeroProps = {
		sub_title: "Products",
		title: "Electronic Lab Notebook (ELN)",
		video_src: GENEMOD_FREEZER,
		text: "Transform your laboratory’s productivity with Genemod’s Electronic Lab Notebook (ELN). Improve the management of experiments, research data, protocols, and all the essential lab documentation.",
		containerOverwrite: styles.heroContainer,
		heroWrapperOverride: styles.heroWrapperOverride,
		autoplay: true,
		bodyColor: "text-secondary",
	};

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "eln-software" }}
			defaultHeader="light"
		>
			<div className={styles.pageWrapper}>
				<Hero {...heroProps} />
				<ElnSoftwareInfoSection />
				<LogosAndG2Section hasLogo />
				<ExperimentManager />
				<CentralProtocolHub />
				<LiveEditing />
				<ComprehensivePM />
				<ModernELN />
				<HomePageTestimonials />
				<SectionFAQ />
			</div>
		</PageLayout>
	);
}
